.tasksPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tasksGrid {
    width: 100%;
}

.task {
    min-width: 300px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}

.task:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}