.teacherCuratorsModule{
    display: block flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.teacherCuratorsGrid {
    width: 100%;
}

.allTeacherCuratorsGrid {
    display: block;
    width: 350px;
}

.chooseAllButton {
    width: 100%;
}

.teacherCurator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}

.teacherCurator:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.projectTeacherCurator {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}

.projectTeacherCurator:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.gridField{
    display: block flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search{
    margin-bottom: 16px;
    width: 250px;
    height: 40px;
}

.curatorNameField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorPhoneField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorEmailField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}