.projectCard {
    background-color: cornsilk;
}

.cardBody {
    overflow: hidden;
}

.cardText {
    white-space: pre-line;
}