.curatorCard {
    background-color: white;
}

.CuratorFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: 1000;
}

.curatorCardHeader {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
}

.curatorButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    max-width: 150px;
}

.cardBody {
    overflow: hidden;
}

.cardText {
    white-space: pre-line;
}