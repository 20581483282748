.curatorField{
    min-width: 350px;
}

.curatorNameField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorPhoneField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.curatorEmailField{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}