.noteCard {
    background-color: cornsilk;
}

.notificationHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardBody {
    overflow: hidden;
}

.cardText {
    white-space: pre-line;
}

.notificationUnread{
    display: flex;
    white-space: nowrap;
    padding: 4px;
    background: rgb(255, 193, 7);
    color: black;
}

.notificationRead{
    display: flex;
    white-space: nowrap;
    padding: 4px;
    background: rgb(25, 135, 84);
    color: white;
}