.TaskDescription{
    display: flex;
    white-space: pre-wrap;
    padding: 4px;
    min-height: 75px;
}

.TaskStatus{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TaskDeadline{
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.TaskFooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.statusDescription{
    display: flex;
    white-space: pre-wrap;
}

.NeedHelpButton{
    width: 150px;
}

.DoneButton{
    width: 150px;
}