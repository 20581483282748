.chooseProjectField {
    background-color: white;
    display: block;
    align-items: center;
    justify-content: center;
}

.chooseProjectFieldBody {
    display: block flex;
    flex-direction: column;
}

.porjectsForm{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.project {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow .15s ease-in-out;
}

.project:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}