.blockCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.flexBlockCenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.flexCenterSA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.flexLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.width100 {
    width: 100%;
}

.fixedSize500x50 {
    max-width: 500px;
    max-height: 50px; 
    min-width: 500px;
    min-height: 50px;
}

.fixedSize500x70 {
    max-width: 500px;
    max-height: 70px; 
    min-width: 500px;
    min-height: 70px;
}

.fixedSize500x100 {
    max-width: 500px;
    max-height: 100px; 
    min-width: 500px;
    min-height: 100px;
}

.flexSizeButton {
    min-width: 350px;
    min-height: 50px;
    max-width: 500px;
    max-height: 50px;
}

.flexTextCard {
    min-width: 350px;
    max-width: 350px;
    min-height: 100px;
    max-height: none;
    width: 100%
}

.textarea {
    white-space: pre-wrap;
}