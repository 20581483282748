.projectStagesPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stageCardInWork{
    background: cornsilk;
    min-width: 350px;
    max-width: 450px;
    padding: 4px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}
.stageCardInWork:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.stageCardNotOpen{
    background: rgb(108, 117, 125, 0.3);
    min-width: 350px;
    max-width: 450px;
    padding: 4px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}
.stageCardNotOpen:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.stageCardDone{
    background: rgb(25, 135, 84, 0.5);
    min-width: 350px;
    max-width: 450px;
    padding: 4px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}
.stageCardDone:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.stageCardNeedRework{
    background: rgb(220, 53, 69, 0.5);
    min-width: 350px;
    max-width: 450px;
    padding: 4px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}
.stageCardNeedRework:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.stageCardNeedCheck{
    background: rgb(255, 202, 44);
    min-width: 350px;
    max-width: 450px;
    padding: 4px;
    transition: box-shadow .15s ease-in-out;
    cursor: pointer;
}
.stageCardNeedCheck:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
