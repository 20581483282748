.descriptionField{
    display: flex;
    white-space: pre-wrap;
}

.downloadFileButton{
    width: 100%;
}

.stageFooter{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}