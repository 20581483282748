.chooseRegions {
    background-color: white;
    display: block;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.chooseRegionsBody {
    display: block flex;
    flex-direction: column;
}

.region {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow .15s ease-in-out;
}

.region:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}