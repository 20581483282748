.taskCard {
    background-color: cornsilk;
}

.cardBody {
    overflow: hidden;
    min-height: 150px;
    min-width: 300px;
}

.cardBodyStatus {
    display: flex;
    padding: 4px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cardText {
    white-space: pre-line;
}