.InWorkStatus{
    display: flex;
    padding: 4px;
    background: rgb(13, 110, 253);
    color: white;
}

.NeedHelpStatus{
    display: flex;
    padding: 4px;
    background: rgb(255, 193, 7);
    color: black;
}

.DoneStatus{
    display: flex;
    padding: 4px;
    background: rgb(25, 135, 84);
    color: white;
}