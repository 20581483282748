.statisticsPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headingRegionCell{
    width: 70%;
}

.headingProblemsCell{
    width: 10%;
}

.tableStyle
.tableCellNoProblems{
    background-color: rgb(25, 135, 84, 0.5);
}

.tableStyle
.tableCellWithProblems{
    background-color: rgb(220, 53, 69, 0.5);
}